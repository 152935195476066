import Time from './utils/Time';
import Experience from './experience/Experience';

export default class App {
  constructor() {
    window.app = this;

    this.time = new Time();
    // this.sizes = new Sizes();
    this.init();
  }

  init() {
    this.setBrowserHeight();

    // Canvas
    const canvas = document.querySelector('canvas.webgl');
    // const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    // gl.getExtension('OES_standard_derivatives');

    this.experience = new Experience({
      canvas,
      isScrolling: false,
    });

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollSmoother);

    this.setListeners();
    this.setSplitText();
    this.setScrollAnimations();
    this.setSmoothScroll();
  }

  setListeners() {
    function debounce(func) {
      let timer;
      return function (event) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, 100, event);
      };
    }

    window.addEventListener('resize', debounce((event) => {
      this.setBrowserHeight();
      this.tl.kill();
      // this.splitHeading.revert();
      this.splitHeading.split();
      this.setScrollAnimations();
    }));
  }

  setBrowserHeight() {
    this.windowVh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${this.windowVh}px`);
  }

  setSplitText() {
    this.splitHeading = new SplitText('#split-heading', {
      type: 'chars,words,lines',
      // position: 'absolute',
    });
  }

  setSmoothScroll() {
    const smoother = ScrollSmoother.create({
      smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true, // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.2, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
      normalizeScroll: 'ontouchstart' in window,
      onUpdate: (self) => {
        this.experience.isScrolling = true;
      },
      onStop: (self) => {
        this.experience.isScrolling = false;
      },
    });
  }

  setScrollAnimations() {
    this.tl = gsap.timeline();

    this.tl
      .to(this.experience.camera.camera.position, {
        y: -0.5,
        z: -5,
        scrollTrigger: {
          trigger: '.content-section',
          start: 'top bottom',
          end: 'bottom bottom',
          scrub: true,
          immediateRender: false,
        },
      })
      .to('#split-sub-heading', {
        y: -120,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: '.content-section',
          start: 'top bottom',
          end: 'top 80%',
          scrub: true,
          immediateRender: false,
        },
      })
      .to(this.splitHeading.chars, {
        y: -150,
        duration: 1.5,
        autoAlpha: 0,
        stagger: 0.05,
        scrollTrigger: {
          trigger: '.content-section',
          start: 'top bottom',
          end: 'top 40%',
          scrub: true,
          immediateRender: false,
        },
      })

      // .to('.hero-section__sub-heading h2', {
      //   y: '-150%',
      //   opacity: 0,
      //   rotateY: '10deg',
      //   scrollTrigger: {
      //     trigger: '.content-section',
      //     start: 'top bottom',
      //     end: 'top center',
      //     scrub: true,
      //     immediateRender: false,
      //   },
      // })
      .to(
        this.experience.renderer.godRays.godRaysPass.screen.material.uniforms
          .density,
        {
          value: 1.3,
          scrollTrigger: {
            trigger: '.content-section',
            start: 'top bottom',
            end: 'bottom bottom',
            scrub: true,
            immediateRender: false,
          },
        },
      );
  }
}
