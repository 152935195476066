export default () => [
  {
    name: 'office',
    data: { index: 0 },
    items: [
      { name: 'officeModel', source: '/models/officev5-compressed.glb' },
      { name: 'coneMask', source: '/models/cone-mask.glb' },
      { name: 'manModel', source: '/models/test-animation2.glb' },
      { name: 'bookTexture', source: '/textures/bookBake-small.webp', type: 'texture' },
      { name: 'deskTexture1', source: '/textures/deskBake1-small.webp', type: 'texture' },
      { name: 'deskTexture2', source: '/textures/deskBake2-small.webp', type: 'texture' },
      { name: 'deskTexture3', source: '/textures/deskBake3-small.webp', type: 'texture' },
      { name: 'chairTexture', source: '/textures/chairBake-small.webp', type: 'texture' },
      { name: 'roomTexture', source: '/textures/roomBake-small.webp', type: 'texture' },
      { name: 'curtainTexture', source: 'textures/curtainPattern.png', type: 'texture' },
    ],
  },
];
